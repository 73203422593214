import { useRef } from "react";
import { Modal } from "antd";
import { PDFViewer, Document, Text, Page,View ,StyleSheet} from '@react-pdf/renderer';
import "./in.scss";
import { fontSize } from "suneditor/src/plugins";
function InTestBDF(props) {
    const { open, setOpen } = props;
    return (

        <Modal
            centered
            open={open}
            okButtonProps={{
                style: {
                    display: "none",
                },
            }}
            cancelButtonProps={{
                style: {
                    display: "none",
                },
            }}
            onCancel={() => setOpen(false)}
            width='100%'
            forceRender
        >
            <div >
                <PDFViewer>
                    <Document>
                        <Page >
                            <Text>sdfdsf</Text>
                            <View style={styles.inphieudtri}>
                                <View style={styles.header}>
                                    <Text style={styles.headerleft}>
                                        Phòng Khám ĐK ABC
                                    </Text>
                                    <Text style={styles.headercontent}>
                                        TỜ CHĂM SÓC
                                    </Text>
                                    <Text style={styles.headerright}>jj</Text>
                                </View>
                                <View style={styles.thongtintodieutri}>
                                        <View>
                                            <Text style={styles.hoten}> Họ tên người bệnh: Winsoft test</Text>
                                            <Text style={styles.tuoi} >Tuổi: 30</Text>
                                            <Text style={styles.gioitinh} >Giới tính: Nam</Text>
                                        </View>
                                        <View>
                                            <Text style={styles.hoten} > Khoa/Phòng:: Winsoft test</Text>
                                            <Text style={styles.tuoi} >Phòng: 30</Text>
                                            <Text style={styles.gioitinh} >Giường: Nam</Text>

                                        </View>
                                        <Text>Chuẩn đoán:</Text>
                                  
                                </View>
                                <View className="ketquatodieutri">
                                    <table>
                                        <th>
                                            <td className="ngay">NGÀY</td>
                                            <td className="giophut">GIỜ PHÚT</td>
                                            <td className="dienbienbenh">DIỄN BIẾN BỆNH</td>
                                            <td className="ylenhdieutri"> Y LỆNH ĐIỀU TRỊ</td>
                                        </th>
                                    </table>
                                </View>

                            </View>
                        </Page>
                    </Document>
                </PDFViewer>
            </div>
        </Modal>




    );
}

export default InTestBDF;
const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4',
    },
    header: {
        flexDirection: 'row',
        fontSize:12
    },
    headerleft:{
        width:'20%'
    },
    headercontent:{
        width:'60%',
        textAlign:'center',
        fontFamily:''
    },
    headerright:{
        width:'20%'
    }
  });
