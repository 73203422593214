const ds2 = [
    {
        ID: "2",
        TDV: "123",
        TDVVT: "Siêu âm tuyến giáp",
        MADVKT: " 18.0001.0001",
        TDVKT: "132",
        DVT: "Lần",
        PTH: "PHÒNG SIÊU ÂM",
        MKPBYT: "",
        LBN: "Khám bệnh",
        MTDBHYT: "18.0001.0001",
        MTT37: "",
        TTTT37: "",
        MTT50: "",
        TTT50: "",
        GM: 0,
        K: 0,
        GC: "",
        GCD: 0,
        BBHC: 0,
        MNBHYT9324_AX: "CHUẨN ĐOÁN HÌNH ẢNH",
        STT: 2,
        CDTTD: 0,
        CDHTTK: 0,
        CDTTL: 0,
        NI: "Siêu âm",
        VATXHD: "",
        MVP: "DV000383",
    },
    {
        ID: "3",
        TDV: "Siêu âm tuyến giáp",
        TDVVT: "Siêu âm tuyến giáp",
        MADVKT: " 18.0001.0001",
        TDVKT: "Siêu âm tuyến giáp",
        DVT: "Lần",
        PTH: "PHÒNG SIÊU ÂM",
        MKPBYT: "",
        LBN: "Khám bệnh",
        MTDBHYT: "18.0001.0001",
        MTT37: "",
        TTTT37: "",
        MTT50: "",
        TTT50: "",
        GM: 0,
        K: 0,
        GC: "",
        GCD: 0,
        BBHC: 0,
        MNBHYT9324_AX: "CHUẨN ĐOÁN HÌNH ẢNH",
        STT: 2,
        CDTTD: 0,
        CDHTTK: 0,
        CDTTL: 0,
        NI: "Siêu âm",
        VATXHD: "",
        MVP: "DV000383",
    },
    {
        TDV: "Siêu âm tuyến giáp",
        TDVVT: "Siêu âm tuyến giáp",
        MADVKT: " 18.0001.0001",
        TDVKT: "Siêu âm tuyến giáp",
        DVT: "Lần",
        PTH: "PHÒNG SIÊU ÂM",
        MKPBYT: "",
        LBN: "Khám bệnh",
        MTDBHYT: "18.0001.0001",
        MTT37: "",
        TTTT37: "",
        MTT50: "",
        TTT50: "",
        GM: 0,
        K: 0,
        GC: "",
        GCD: 0,
        BBHC: 0,
        MNBHYT9324_AX: "CHUẨN ĐOÁN HÌNH ẢNH",
        STT: 2,
        CDTTD: 0,
        CDHTTK: 0,
        CDTTL: 0,
        NI: "Siêu âm",
        VATXHD: "",
        MVP: "DV000383",
    },
    {
        TDV: "Siêu âm tuyến giáp",
        TDVVT: "Siêu âm tuyến giáp",
        MADVKT: " 18.0001.0001",
        TDVKT: "Siêu âm tuyến giáp",
        DVT: "Lần",
        PTH: "PHÒNG SIÊU ÂM",
        MKPBYT: "",
        LBN: "Khám bệnh",
        MTDBHYT: "18.0001.0001",
        MTT37: "",
        TTTT37: "",
        MTT50: "",
        TTT50: "",
        GM: 0,
        K: 0,
        GC: "",
        GCD: 0,
        BBHC: 0,
        MNBHYT9324_AX: "CHUẨN ĐOÁN HÌNH ẢNH",
        STT: 2,
        CDTTD: 0,
        CDHTTK: 0,
        CDTTL: 0,
        NI: "Siêu âm",
        VATXHD: "",
        MVP: "DV000383",
    },
    {
        TDV: "Siêu âm tuyến giáp",
        TDVVT: "Siêu âm tuyến giáp",
        MADVKT: " 18.0001.0001",
        TDVKT: "Siêu âm tuyến giáp",
        DVT: "Lần",
        PTH: "PHÒNG SIÊU ÂM",
        MKPBYT: "",
        LBN: "Khám bệnh",
        MTDBHYT: "18.0001.0001",
        MTT37: "",
        TTTT37: "",
        MTT50: "",
        TTT50: "",
        GM: 0,
        K: 0,
        GC: "",
        GCD: 0,
        BBHC: 0,
        MNBHYT9324_AX: "CHUẨN ĐOÁN HÌNH ẢNH",
        STT: 2,
        CDTTD: 0,
        CDHTTK: 0,
        CDTTL: 0,
        NI: "Siêu âm",
        VATXHD: "",
        MVP: "DV000383",
    },
    {
        TDV: "Siêu âm tuyến giáp",
        TDVVT: "Siêu âm tuyến giáp",
        MADVKT: " 18.0001.0001",
        TDVKT: "Siêu âm tuyến giáp",
        DVT: "Lần",
        PTH: "PHÒNG SIÊU ÂM",
        MKPBYT: "",
        LBN: "Khám bệnh",
        MTDBHYT: "18.0001.0001",
        MTT37: "",
        TTTT37: "",
        MTT50: "",
        TTT50: "",
        GM: 0,
        K: 0,
        GC: "",
        GCD: 0,
        BBHC: 0,
        MNBHYT9324_AX: "CHUẨN ĐOÁN HÌNH ẢNH",
        STT: 2,
        CDTTD: 0,
        CDHTTK: 0,
        CDTTL: 0,
        NI: "Siêu âm",
        VATXHD: "",
        MVP: "DV000383",
    },
    {
        TDV: "Siêu âm tuyến giáp",
        TDVVT: "Siêu âm tuyến giáp",
        MADVKT: " 18.0001.0001",
        TDVKT: "Siêu âm tuyến giáp",
        DVT: "Lần",
        PTH: "PHÒNG SIÊU ÂM",
        MKPBYT: "",
        LBN: "Khám bệnh",
        MTDBHYT: "18.0001.0001",
        MTT37: "",
        TTTT37: "",
        MTT50: "",
        TTT50: "",
        GM: 0,
        K: 0,
        GC: "",
        GCD: 0,
        BBHC: 0,
        MNBHYT9324_AX: "CHUẨN ĐOÁN HÌNH ẢNH",
        STT: 2,
        CDTTD: 0,
        CDHTTK: 0,
        CDTTL: 0,
        NI: "Siêu âm",
        VATXHD: "",
        MVP: "DV000383",
    },
    {
        TDV: "Siêu âm tuyến giáp",
        TDVVT: "Siêu âm tuyến giáp",
        MADVKT: " 18.0001.0001",
        TDVKT: "Siêu âm tuyến giáp",
        DVT: "Lần",
        PTH: "PHÒNG SIÊU ÂM",
        MKPBYT: "",
        LBN: "Khám bệnh",
        MTDBHYT: "18.0001.0001",
        MTT37: "",
        TTTT37: "",
        MTT50: "",
        TTT50: "",
        GM: 0,
        K: 0,
        GC: "",
        GCD: 0,
        BBHC: 0,
        MNBHYT9324_AX: "CHUẨN ĐOÁN HÌNH ẢNH",
        STT: 2,
        CDTTD: 0,
        CDHTTK: 0,
        CDTTL: 0,
        NI: "Siêu âm",
        VATXHD: "",
        MVP: "DV000383",
    },
    {
        TDV: "Siêu âm tuyến giáp",
        TDVVT: "Siêu âm tuyến giáp",
        MADVKT: " 18.0001.0001",
        TDVKT: "Siêu âm tuyến giáp",
        DVT: "Lần",
        PTH: "PHÒNG SIÊU ÂM",
        MKPBYT: "",
        LBN: "Khám bệnh",
        MTDBHYT: "18.0001.0001",
        MTT37: "",
        TTTT37: "",
        MTT50: "",
        TTT50: "",
        GM: 0,
        K: 0,
        GC: "",
        GCD: 0,
        BBHC: 0,
        MNBHYT9324_AX: "CHUẨN ĐOÁN HÌNH ẢNH",
        STT: 2,
        CDTTD: 0,
        CDHTTK: 0,
        CDTTL: 0,
        NI: "Siêu âm",
        VATXHD: "",
        MVP: "DV000383",
    },
    {
        TDV: "Siêu âm tuyến giáp",
        TDVVT: "Siêu âm tuyến giáp",
        MADVKT: " 18.0001.0001",
        TDVKT: "Siêu âm tuyến giáp",
        DVT: "Lần",
        PTH: "PHÒNG SIÊU ÂM",
        MKPBYT: "",
        LBN: "Khám bệnh",
        MTDBHYT: "18.0001.0001",
        MTT37: "",
        TTTT37: "",
        MTT50: "",
        TTT50: "",
        GM: 0,
        K: 0,
        GC: "",
        GCD: 0,
        BBHC: 0,
        MNBHYT9324_AX: "CHUẨN ĐOÁN HÌNH ẢNH",
        STT: 2,
        CDTTD: 0,
        CDHTTK: 0,
        CDTTL: 0,
        NI: "Siêu âm",
        VATXHD: "",
        MVP: "DV000383",
    },
    {
        TDV: "Siêu âm tuyến giáp",
        TDVVT: "Siêu âm tuyến giáp",
        MADVKT: " 18.0001.0001",
        TDVKT: "Siêu âm tuyến giáp",
        DVT: "Lần",
        PTH: "PHÒNG SIÊU ÂM",
        MKPBYT: "",
        LBN: "Khám bệnh",
        MTDBHYT: "18.0001.0001",
        MTT37: "",
        TTTT37: "",
        MTT50: "",
        TTT50: "",
        GM: 0,
        K: 0,
        GC: "",
        GCD: 0,
        BBHC: 0,
        MNBHYT9324_AX: "CHUẨN ĐOÁN HÌNH ẢNH",
        STT: 2,
        CDTTD: 0,
        CDHTTK: 0,
        CDTTL: 0,
        NI: "Siêu âm",
        VATXHD: "",
        MVP: "DV000383",
    },
    {
        TDV: "Siêu âm tuyến giáp",
        TDVVT: "Siêu âm tuyến giáp",
        MADVKT: " 18.0001.0001",
        TDVKT: "Siêu âm tuyến giáp",
        DVT: "Lần",
        PTH: "PHÒNG SIÊU ÂM",
        MKPBYT: "",
        LBN: "Khám bệnh",
        MTDBHYT: "18.0001.0001",
        MTT37: "",
        TTTT37: "",
        MTT50: "",
        TTT50: "",
        GM: 0,
        K: 0,
        GC: "",
        GCD: 0,
        BBHC: 0,
        MNBHYT9324_AX: "CHUẨN ĐOÁN HÌNH ẢNH",
        STT: 2,
        CDTTD: 0,
        CDHTTK: 0,
        CDTTL: 0,
        NI: "Siêu âm",
        VATXHD: "",
        MVP: "DV000383",
    },
    {
        TDV: "Siêu âm tuyến giáp",
        TDVVT: "Siêu âm tuyến giáp",
        MADVKT: " 18.0001.0001",
        TDVKT: "Siêu âm tuyến giáp",
        DVT: "Lần",
        PTH: "PHÒNG SIÊU ÂM",
        MKPBYT: "",
        LBN: "Khám bệnh",
        MTDBHYT: "18.0001.0001",
        MTT37: "",
        TTTT37: "",
        MTT50: "",
        TTT50: "",
        GM: 0,
        K: 0,
        GC: "",
        GCD: 0,
        BBHC: 0,
        MNBHYT9324_AX: "CHUẨN ĐOÁN HÌNH ẢNH",
        STT: 2,
        CDTTD: 0,
        CDHTTK: 0,
        CDTTL: 0,
        NI: "Siêu âm",
        VATXHD: "",
        MVP: "DV000383",
    },
    {
        TDV: "Siêu âm tuyến giáp",
        TDVVT: "Siêu âm tuyến giáp",
        MADVKT: " 18.0001.0001",
        TDVKT: "Siêu âm tuyến giáp",
        DVT: "Lần",
        PTH: "PHÒNG SIÊU ÂM",
        MKPBYT: "",
        LBN: "Khám bệnh",
        MTDBHYT: "18.0001.0001",
        MTT37: "",
        TTTT37: "",
        MTT50: "",
        TTT50: "",
        GM: 0,
        K: 0,
        GC: "",
        GCD: 0,
        BBHC: 0,
        MNBHYT9324_AX: "CHUẨN ĐOÁN HÌNH ẢNH",
        STT: 2,
        CDTTD: 0,
        CDHTTK: 0,
        CDTTL: 0,
        NI: "Siêu âm",
        VATXHD: "",
        MVP: "DV000383",
    },
    {
        TDV: "Siêu âm tuyến giáp",
        TDVVT: "Siêu âm tuyến giáp",
        MADVKT: " 18.0001.0001",
        TDVKT: "Siêu âm tuyến giáp",
        DVT: "Lần",
        PTH: "PHÒNG SIÊU ÂM",
        MKPBYT: "",
        LBN: "Khám bệnh",
        MTDBHYT: "18.0001.0001",
        MTT37: "",
        TTTT37: "",
        MTT50: "",
        TTT50: "",
        GM: 0,
        K: 0,
        GC: "",
        GCD: 0,
        BBHC: 0,
        MNBHYT9324_AX: "CHUẨN ĐOÁN HÌNH ẢNH",
        STT: 2,
        CDTTD: 0,
        CDHTTK: 0,
        CDTTL: 0,
        NI: "Siêu âm",
        VATXHD: "",
        MVP: "DV000383",
    },
    {
        TDV: "Siêu âm tuyến giáp",
        TDVVT: "Siêu âm tuyến giáp",
        MADVKT: " 18.0001.0001",
        TDVKT: "Siêu âm tuyến giáp",
        DVT: "Lần",
        PTH: "PHÒNG SIÊU ÂM",
        MKPBYT: "",
        LBN: "Khám bệnh",
        MTDBHYT: "18.0001.0001",
        MTT37: "",
        TTTT37: "",
        MTT50: "",
        TTT50: "",
        GM: 0,
        K: 0,
        GC: "",
        GCD: 0,
        BBHC: 0,
        MNBHYT9324_AX: "CHUẨN ĐOÁN HÌNH ẢNH",
        STT: 2,
        CDTTD: 0,
        CDHTTK: 0,
        CDTTL: 0,
        NI: "Siêu âm",
        VATXHD: "",
        MVP: "DV000383",
    },
    {
        TDV: "Siêu âm tuyến giáp",
        TDVVT: "Siêu âm tuyến giáp",
        MADVKT: " 18.0001.0001",
        TDVKT: "Siêu âm tuyến giáp",
        DVT: "Lần",
        PTH: "PHÒNG SIÊU ÂM",
        MKPBYT: "",
        LBN: "Khám bệnh",
        MTDBHYT: "18.0001.0001",
        MTT37: "",
        TTTT37: "",
        MTT50: "",
        TTT50: "",
        GM: 0,
        K: 0,
        GC: "",
        GCD: 0,
        BBHC: 0,
        MNBHYT9324_AX: "CHUẨN ĐOÁN HÌNH ẢNH",
        STT: 2,
        CDTTD: 0,
        CDHTTK: 0,
        CDTTL: 0,
        NI: "Siêu âm",
        VATXHD: "",
        MVP: "DV000383",
    },
    {
        TDV: "Siêu âm tuyến giáp",
        TDVVT: "Siêu âm tuyến giáp",
        MADVKT: " 18.0001.0001",
        TDVKT: "Siêu âm tuyến giáp",
        DVT: "Lần",
        PTH: "PHÒNG SIÊU ÂM",
        MKPBYT: "",
        LBN: "Khám bệnh",
        MTDBHYT: "18.0001.0001",
        MTT37: "",
        TTTT37: "",
        MTT50: "",
        TTT50: "",
        GM: 0,
        K: 0,
        GC: "",
        GCD: 0,
        BBHC: 0,
        MNBHYT9324_AX: "CHUẨN ĐOÁN HÌNH ẢNH",
        STT: 2,
        CDTTD: 0,
        CDHTTK: 0,
        CDTTL: 0,
        NI: "Siêu âm",
        VATXHD: "",
        MVP: "DV000383",
    },
    {
        TDV: "Siêu âm tuyến giáp",
        TDVVT: "Siêu âm tuyến giáp",
        MADVKT: " 18.0001.0001",
        TDVKT: "Siêu âm tuyến giáp",
        DVT: "Lần",
        PTH: "PHÒNG SIÊU ÂM",
        MKPBYT: "",
        LBN: "Khám bệnh",
        MTDBHYT: "18.0001.0001",
        MTT37: "",
        TTTT37: "",
        MTT50: "",
        TTT50: "",
        GM: 0,
        K: 0,
        GC: "",
        GCD: 0,
        BBHC: 0,
        MNBHYT9324_AX: "CHUẨN ĐOÁN HÌNH ẢNH",
        STT: 2,
        CDTTD: 0,
        CDHTTK: 0,
        CDTTL: 0,
        NI: "Siêu âm",
        VATXHD: "",
        MVP: "DV000383",
    },
    {
        TDV: "Siêu âm tuyến giáp",
        TDVVT: "Siêu âm tuyến giáp",
        MADVKT: " 18.0001.0001",
        TDVKT: "Siêu âm tuyến giáp",
        DVT: "Lần",
        PTH: "PHÒNG SIÊU ÂM",
        MKPBYT: "",
        LBN: "Khám bệnh",
        MTDBHYT: "18.0001.0001",
        MTT37: "",
        TTTT37: "",
        MTT50: "",
        TTT50: "",
        GM: 0,
        K: 0,
        GC: "",
        GCD: 0,
        BBHC: 0,
        MNBHYT9324_AX: "CHUẨN ĐOÁN HÌNH ẢNH",
        STT: 2,
        CDTTD: 0,
        CDHTTK: 0,
        CDTTL: 0,
        NI: "Siêu âm",
        VATXHD: "",
        MVP: "DV000383",
    },
    {
        TDV: "Siêu âm tuyến giáp",
        TDVVT: "Siêu âm tuyến giáp",
        MADVKT: " 18.0001.0001",
        TDVKT: "Siêu âm tuyến giáp",
        DVT: "Lần",
        PTH: "PHÒNG SIÊU ÂM",
        MKPBYT: "",
        LBN: "Khám bệnh",
        MTDBHYT: "18.0001.0001",
        MTT37: "",
        TTTT37: "",
        MTT50: "",
        TTT50: "",
        GM: 0,
        K: 0,
        GC: "",
        GCD: 0,
        BBHC: 0,
        MNBHYT9324_AX: "CHUẨN ĐOÁN HÌNH ẢNH",
        STT: 2,
        CDTTD: 0,
        CDHTTK: 0,
        CDTTL: 0,
        NI: "Siêu âm",
        VATXHD: "",
        MVP: "DV000383",
    },
];

export default ds2;
