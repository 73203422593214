const data2 = [
    {
        ngay: "17/5/2022",
        mach: 20,
        nhietdo: 20,
        huyetap: 20,
        nang: 20,
        cao: 20,
        nhiptho: 20,
        vongnguc: 20,
    },
    {
        ngay: "17/5/2022",
        mach: 20,
        nhietdo: 20,
        huyetap: 20,
        nang: 20,
        cao: 20,
        nhiptho: 20,
        vongnguc: 20,
    },
    {
        ngay: "17/5/2022",
        mach: 20,
        nhietdo: 20,
        huyetap: 20,
        nang: 20,
        cao: 20,
        nhiptho: 20,
        vongnguc: 20,
    },
    {
        ngay: "17/5/2022",
        mach: 20,
        nhietdo: 20,
        huyetap: 20,
        nang: 20,
        cao: 20,
        nhiptho: 20,
        vongnguc: 20,
    },
    {
        ngay: "17/5/2022",
        mach: 20,
        nhietdo: 20,
        huyetap: 20,
        nang: 20,
        cao: 20,
        nhiptho: 20,
        vongnguc: 20,
    },
    {
        ngay: "17/5/2022",
        mach: 20,
        nhietdo: 20,
        huyetap: 20,
        nang: 20,
        cao: 20,
        nhiptho: 20,
        vongnguc: 20,
    },
    {
        ngay: "17/5/2022",
        mach: 20,
        nhietdo: 20,
        huyetap: 20,
        nang: 20,
        cao: 20,
        nhiptho: 20,
        vongnguc: 20,
    },
    {
        ngay: "17/5/2022",
        mach: 20,
        nhietdo: 20,
        huyetap: 20,
        nang: 20,
        cao: 20,
        nhiptho: 20,
        vongnguc: 20,
    },
    {
        ngay: "17/5/2022",
        mach: 20,
        nhietdo: 20,
        huyetap: 20,
        nang: 20,
        cao: 20,
        nhiptho: 20,
        vongnguc: 20,
    },
];
export default data2;
